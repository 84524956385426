import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Container,
    Box,
    Paper
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';

function LoginPage() {
    const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

    const callPrivateAPI = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/private`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await response.json();
            enqueueSnackbar('Successfully logged in!');
        } catch (error) {
            enqueueSnackbar('login failed!', {variant: 'error'});
        }
    };

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Joinery Template
                    </Typography>
                    {isAuthenticated ? (
                        <Button color="inherit" onClick={handleLogout}>Log Out</Button>
                    ) : (
                        <Button color="inherit" onClick={() => loginWithRedirect()}>Log In</Button>
                    )}
                </Toolbar>
            </AppBar>
            <Container maxWidth="sm" sx={{ mt: 4 }}>
                <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome
                    </Typography>
                    <Typography variant="body1" component="p">
                        {isAuthenticated
                            ? 'You\'re logged in! Try calling the private API.'
                            : 'Please log in.'}
                    </Typography>
                    {isAuthenticated && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={callPrivateAPI}
                            sx={{ mt: 2 }}
                        >
                            Call Private API
                        </Button>
                    )}
                </Paper>
            </Container>
        </Box>
    );
}

export default LoginPage;