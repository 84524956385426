import React, { ReactNode } from 'react';

import Header from '../common/Header/Header';

interface PageLayoutProps {
    children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    return (
        <div className="page-layout">
            <Header />
            <main>{children}</main>
        </div>
    );
};

export default PageLayout;