import React from 'react';

import {useAuth0} from '@auth0/auth0-react';
import {CircularProgress, Box} from '@mui/material';
import {Navigate} from 'react-router-dom';

interface ProtectedRouteProps {
    element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    const {isAuthenticated, isLoading} = useAuth0();

    if (isLoading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center',
                alignItems: 'center', height: '100vh'}}>
                <CircularProgress/>
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace/>;
    }

    return element;
};

export default ProtectedRoute;