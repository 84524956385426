import {Container, Grid} from '@mui/material';
import {useQuery} from '@tanstack/react-query';

import {listUsersApiV1UserUsersGet} from '../../client';
import UserCard from '../UserCard/UserCard';

interface UsersListProps {
    userCreationPending: boolean
}

const UsersList = (props: UsersListProps) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['getUsers', props.userCreationPending],
        queryFn: () => listUsersApiV1UserUsersGet()
    });

    if (isPending) {
        return (<div>Loading...</div>);
    }

    if (error) {
        return (<div>Error! {JSON.stringify(error)}</div>);
    }

    const usersList = data.data || [];

    return (
        <div>
            <div>
                Here are all the users registered on our site:
                <Container maxWidth="lg" sx={{ py: 4 }}>
                    <Grid
                        container
                        spacing={3}  // Gap between cards
                        justifyContent="flex-start"
                        alignItems="stretch"
                    >
                        {usersList.map((user) => (
                            <Grid
                                container
                                key={user.id}
                                xs={12}    // Full width on mobile
                                sm={6}     // 2 cards per row on small screens
                                md={4}     // 3 cards per row on medium screens
                                lg={4}     // 3 cards per row on large screens
                            >
                                <UserCard user={user} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default UsersList;