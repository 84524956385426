import React from 'react';

import { Mail, AccessTime } from '@mui/icons-material';
import {
    Card,
    CardContent,
    CardHeader,
    Avatar,
    Typography,
    Box,
} from '@mui/material';

import {User} from '../../client';

interface UserCardProps {
    user: User
}

const UserCard = (props: UserCardProps) => {
    const formatDate = (dateString: string) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Get initials for avatar fallback
    const getInitials = (name: string) => {
        return name.split('@')[0].substring(0, 2).toUpperCase();
    };

    return (
        <Card sx={{
            maxWidth: 400,
            width: '100%',
            transition: 'box-shadow 0.3s',
            '&:hover': {
                boxShadow: 6
            }
        }}>
            <CardHeader
                avatar={
                    <Avatar
                        src={props.user.picture}
                        alt={props.user.nickname}
                        sx={{ width: 56, height: 56 }}
                    >
                        {getInitials(props.user.name)}
                    </Avatar>
                }
                title={
                    <Typography variant="h6" component="h3">
                        {props.user.nickname}
                    </Typography>
                }
                subheader={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        <Mail fontSize="small" color="action" />
                        <Typography variant="body2" color="text.secondary">
                            {props.user.email}
                        </Typography>
                    </Box>
                }
            />
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <AccessTime fontSize="small" color="action" />
                    <Typography variant="body2" color="text.secondary">
                        Joined {formatDate(props.user.created_at)}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default UserCard;